import React from "react";
import "./Services.css";
import { servicesData } from "../../data/servicesData";

const Programs = () => {
  return (
    <div className="Programs" id="services">
      <div className="programs-header">
        <span className="stroke-text">Explore our</span>
        <span>Services</span>
        <span className="stroke-text">to help you</span>
      </div>

      <div className="program-categories">
        {servicesData.map((service) => (
          <div className="category">
            {service.image}
            <span>{service.heading}</span>
            <span>{service.details}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
