import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r5lrggd", //email services - service id
        "template_nw9dl1e", //email templates - template id
        form.current,
        "FumQ7-lj5MYxSs9no" //public profile key
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset(); // Reset the form after successful email submission
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">READY TO LEVEL UP</span>
        </div>
        <div>
          <span className="stroke-text">YOUR BUSINESS WITH US?</span>
        </div>
        <div>
          <span
            style={{
              color: "var(--gray)",
              fontWeight: "normal",
              fontSize: "1rem",
            }}
          >
            Leave your email and we will contact you
          </span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
          <input
            type="email"
            name="email"
            placeholder="Enter your Email Address"
            required
          />
          <button className="btn btn-j">Send</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
