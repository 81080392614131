import React from "react";
import "./Footer.css";

import Facebook from "../../assets/facebook.png";
import LinkedIn from "../../assets/linkedin.png";
import Whatsapp from "../../assets/whatsapp.png";

const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
          <a className="social-links" href="https://www.facebook.com/VAVTES/">
            <img src={Facebook} alt="Facebook" />
          </a>
          <a
            className="social-links"
            href="https://www.linkedin.com/company/vavtes-studio/"
          >
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
          <a className="social-links" href="https://wa.me/60182425832">
            <img src={Whatsapp} alt="WhatsApp" />
          </a>
        </div>

        <div style={{ color: "white" }}>© Powered by Techtify</div>
      </div>
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
