import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.png";
import image3 from "../assets/t-image3.png";

export const testimonialsData = [
  {
    image: image1,
    review: (
      <span>
        The
        <span style={{ color: "var(--orange)" }}>
          {" "}
          Basic Localization Plan{" "}
        </span>
        offers essential localization services to establish your presence in a
        target market. It includes accurate translation of your content into the
        desired language, cultural adaptation to resonate with the local
        audience, and linguistic quality assurance.
      </span>
    ),
    name: "START-UPS, SINGLE PROJECT",
  },
  {
    image: image3,
    review: (
      <span>
        The
        <span style={{ color: "var(--orange)" }}> VavtesLexiBot Plan </span>
        offers lightning-fast and efficient translation services, powered by
        advanced AI technology. With this plan, you can instantly translate your
        content within 24 hours, leveraging the speed and accuracy of artificial
        intelligence.
      </span>
    ),
    name: "fast delivery & budget saving",
  },
  {
    image: image2,
    review: (
      <span>
        The
        <span style={{ color: "var(--orange)" }}>
          {" "}
          Business Localization Plan{" "}
        </span>
        is designed for businesses seeking a comprehensive and scalable approach
        to localization. It provides the necessary services and support to
        effectively adapt your content to new markets, ensuring a seamless user
        experience and successful expansion into global markets.
      </span>
    ),
    name: "Small & medium-sized enterprises",
  },
];
