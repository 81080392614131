import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="Contact" id="contact">
      <div className="left-j">
        <div>
          <span>CONTACT US</span>
        </div>
      </div>
      <div>
        <div style={{ color: "var(--orange)", marginBottom: "10px" }}>
          Address
        </div>
        <div style={{ color: "white", letterSpacing: "2px" }}>
          89 Jalan 5/3D, Acacia Park, Bandar Tasik Puteri, Selangor 48020, MY
        </div>
      </div>
      <div>
        <div style={{ color: "var(--orange)", marginBottom: "10px" }}>
          Phone
        </div>
        <div style={{ color: "white", letterSpacing: "2px" }}>
          +60 18-242 5832
        </div>
      </div>
      <div>
        <div style={{ color: "var(--orange)", marginBottom: "10px" }}>
          Email
        </div>
        <div style={{ color: "white", letterSpacing: "2px" }}>
          admin@vavtes-studio.com
        </div>
      </div>
    </div>
  );
};

export default Contact;
